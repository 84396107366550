import { useCallback, useState } from 'react';
import { commissioningProvider } from '../../services/api';
import { IDynamicFilterData } from '../../interfaces';
import { useStorage } from '../useStorage';

export interface ICommission {
  razaoSocial: string;
  numeroNota: string;
  percela: string;
  dataParcela: string;
  dataPedido: string;
  valorPedido: number;
  valorPago: number;
  status: string;
}

const toastConfig = {
  autoClose: 3000,
  hideProgressBar: true,
  type: 'success',
};

export const useCommissioning = (): [
  {
    isLoading: boolean;
    error: unknown;
    commissions: ICommission[];
    totalItems: number;
  },
  (
    filters?: IDynamicFilterData & {
      extraType: string;
      currentPage?: number;
      paginationSize?: number;
    },
  ) => void,
  (
    filters?: IDynamicFilterData & {
      extraType: string;
      currentPage?: number;
      paginationSize?: number;
    },
  ) => Promise<void>,
] => {
  const { getItemOnStorage } = useStorage();
  const currentUser = getItemOnStorage('currentUser');
  const user = JSON.parse(currentUser || '');
  const vendorCode = user.vendedorAdicional;

  const [state, setState] = useState<{
    isLoading: boolean;
    error: unknown;
    commissions: ICommission[];
    totalItems: number;
  }>({
    isLoading: false,
    error: null,
    commissions: [],
    totalItems: 0,
  });

  const fetchCommissions = useCallback(
    async (
      filters: IDynamicFilterData & {
        extraType: string;
        currentPage?: number;
        paginationSize?: number;
      } = {
        type: 'nfe',
        term: '',
        dateStart: '',
        dateEnd: '',
        extraType: '0',
      },
    ) => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));

        const vendedorQuery = vendorCode ? `&vendedor=${vendorCode}` : '';

        const response = await commissioningProvider.get(
          `/api/Comissoes/ConsultaPagamentos?type=${filters.type}&term=${filters.term}&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&extraType=${filters.extraType}${vendedorQuery}&page=${filters.currentPage}&pageSize=${filters.paginationSize}`,
        );

        setState(prevState => ({
          ...prevState,
          commissions: response.data.data.pagamentos,
          totalItems: response.data.data.pagination.totalItems,
          error: null,
        }));
      } catch (err: any) {
        setState(prevState => ({
          ...prevState,
          error: err,
          commissions: [],
          totalItems: 0,
        }));
      } finally {
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    },
    [],
  );

  const exportCommissions = useCallback(
    async (
      filters: IDynamicFilterData & { extraType: string } = {
        type: 'nfe',
        term: '',
        dateStart: '',
        dateEnd: '',
        extraType: '0',
      },
    ) => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));

        const vendedorQuery = vendorCode ? `&vendedor=${vendorCode}` : '';
        const response = await commissioningProvider.get(
          `/api/Comissoes/ExportarPagamentos?type=${filters.type}&term=${filters.term}&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&extraType=${filters.extraType}${vendedorQuery}`,
          {
            responseType: 'blob',
          },
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'comissoes_export.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err: any) {
        setState(prevState => ({
          ...prevState,
          error: err,
        }));
      } finally {
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    },
    [],
  );

  return [state, fetchCommissions, exportCommissions];
};
