import styled from 'styled-components';

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const DialogContainer = styled.div`
  background: #fff;
  border-radius: 16px;
  width: 80%;
  max-width: 77%;
  max-width: 1290px;
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const Title = styled.h1`
  color: #033b71;
  font-size: 2.3rem;
  line-height: 40px;
  margin: 0 0 16px 0;
  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 0 0 32px 0;
    line-height: 37px;
  }
`;

export const Description = styled.p`
  color: #033b71;
  font-size: 1.2rem;
  margin: 0 0 16px 0;
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin: 0 0 32px 0;
  }
`;

export const Timer = styled.span`
  font-size: 0.875rem;
  color: #033b71;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

export const ImageContainer = styled.div`
  max-width: 41%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledImage = styled.img`
  border-radius: 16px;
  width: 100%;
  height: auto;
  object-fit: cover;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  background-color: #ff9f00;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 24px;
  font-size: 1rem;
  margin-top: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  @media (max-width: 768px) {
    margin-top: 32px;
  }
  &:hover {
    background-color: #e58c00;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10%;

  @media (min-width: 769px) {
    flex-direction: row;

    & > div:first-child {
      flex: 3;
    }

    & > ${ImageContainer} {
      flex: 2;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
