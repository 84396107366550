interface ErroResponse {
  id: number;
  erro: string;
  metodo: string;
  nomeUsuarioAdmin: string;
  revendaImpactada: string;
  status: number;
  descricaoPerfil: string;
  data: string;
}

export interface FormSearch {
  search?: string;
}

export type ErroResponseArray = ErroResponse[];

interface ErrorDetails {
  Message: string;
  StackTrace: string;
}

export const getStatusInfo = (status: number) => {
  if (status >= 200 && status < 300) {
    return { text: `Sucesso (${status})`, color: '#1BC167' };
  } else if (status >= 300 && status < 400) {
    return { text: `Redirecionado (${status})`, color: '#1BC167' };
  } else if (status >= 400 && status < 500) {
    return { text: `Erro (${status})`, color: '#ED5252' };
  } else if (status >= 500) {
    return { text: `Erro do Servidor (${status})`, color: '#ED5252' };
  } else {
    return { text: `Desconhecido (${status})`, color: '#000000' };
  }
};
