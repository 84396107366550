import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ModalContent } from '../../styles/lib/modal';

interface ModalComponentProps {
  onRedirect: () => void;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ onRedirect }) => {
  const [open, setOpen] = useState(true);
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      onRedirect();
    }
  }, [counter, onRedirect]);

  return (
    <Modal open={open} onClose={() => setOpen(false)} center>
      <ModalContent>
        <h2>Olá!</h2>
        <p>
          Você está sendo direcionado ao nosso novo Portal de Boletos -
          Autosserviço! Caso ainda não tenha recebido o seu acesso, por favor,
          solicite seu cadastro ao vendedor responsável por atendê-lo.
        </p>
        <p>Redirecionando em {counter} segundos...</p>
        <button onClick={onRedirect}>Confirmar</button>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
