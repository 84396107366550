import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid #e3e4e6;
  border-radius: 10px;
  transition: ease 0.4s;
  width: 100%;
  padding: 24px;

  &:hover {
    border: 1px solid #f5f5f5;
  }

  &.active {
    display: flex;
    border: 1px solid #bcbec0;
    background: #f5f5f5;
  }

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

export const Top = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: start;
  border-radius: 10px;
`;

export const Bottom = styled.div`
  display: none;
  position: relative;
  justify-content: space-between;

  &.active {
    display: flex;
  }

  margin-top: 24px;
  @media (max-width: 1024px) {
    margin-top: 0px;
    flex-direction: column;
    gap: 16px;
  }
`;

export const TopWrapper = styled(Top)`
  background: transparent;
  transition: ease 0.4s;
  svg {
    align-self: center;
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 160px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const GroupButtons = styled.div`
  display: flex;
  width: 100%;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #2c2c2c;

  span {
    font-weight: 400;
  }

  &.big {
    font-size: 18px;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    font-size: 12px;

    &.big {
      font-size: 14px;
    }
  }

  &.bold {
    .span {
      font-size: 14px;
      font-weight: 700;
      color: rgb(44, 44, 44);
    }
  }
`;
