import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useOrder, useStorage } from '../../hooks';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
  OrderCardDanfe,
  Pagination,
  DynamicFilter,
} from '../../components';
import LoadingAnimated from '../../assets/loading-animated.svg';
import {
  IDynamicFilterData,
  IDynamicFilterSelectOptions,
  IOrderProps,
} from '../../interfaces';

export const SendDanfeXML: React.FC = () => {
  const {
    errors,
    isLoading,
    loadedItems,
    getNewPaginatedOrders,
    qtdeRegistros,
  } = useOrder();

  const today = new Date();

  // 45 dias retroativos
  const startDate = new Date();
  startDate.setDate(today.getDate() - 45);

  // 12 meses retroativos
  const blockDate = new Date();
  blockDate.setMonth(today.getMonth() - 12);

  const [dateStart, setDateStart] = useState<string>(
    startDate.toISOString().split('T')[0],
  );
  const [dateEnd, setDateEnd] = useState<string>(
    today.toISOString().split('T')[0],
  );

  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
  });

  const { getItemOnStorage } = useStorage();
  const currentUser = getItemOnStorage('currentUser');
  const user = JSON.parse(currentUser || '');

  const totalPages = Math.ceil(qtdeRegistros / pagination.size);
  const isLastPage = loadedItems.length >= loadedItems[0]?.sTotalRegistros;

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= totalPages) {
      window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
      setPagination({
        current: index,
        size: pagination.size,
      });
      getNewPaginatedOrders({
        ...filters,
        page: index,
        size: pagination.size,
      });
    }
  };

  const searchOrders = async (filterData: IDynamicFilterData) => {
    if (filterData.type === 'cnpj') {
      const maskedTerm = filterData.term.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );

      setFilters({ ...filterData, term: maskedTerm });
    }

    await getNewPaginatedOrders({
      ...filterData,
      isNewSearch: true,
      page: 1,
      size: pagination.size,
    });
  };

  const filterSelectOptions: IDynamicFilterSelectOptions[] = [
    { value: 'clienteNome', description: 'Nome do Cliente' },
    { value: 'cnpj', description: 'CNPJ' },
    { value: 'numNota', description: 'Número da Nota Fiscal' },
    { value: 'numPedAllied', description: 'Número do Pedido Allied' },
    { value: 'numPedEcom', description: 'Número do Pedido E-Commerce' },
  ];

  const [filters, setFilters] = useState<IDynamicFilterData>({
    type: filterSelectOptions[0].value,
    term: '',
    dateStart: dateStart,
    dateEnd: dateEnd,
  });

  useEffect(() => {
    getNewPaginatedOrders({
      ...filters,
      page: pagination.current,
      size: pagination.size,
    });
  }, []);

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <S.Content>
        <Breadcrumb paths={paths} />
        <S.Title>Envio de DANFE e XML</S.Title>
        <DynamicFilter
          calendar
          filterSelectOptions={filterSelectOptions}
          filters={filters}
          setFilters={setFilters}
          isLoading={isLoading}
          searchFilters={searchOrders}
        />
        <S.Orders>
          <S.TitleSmall>Pedidos</S.TitleSmall>
          <S.Items>
            {!isLoading &&
              loadedItems?.map(
                (element: IOrderProps, index: React.Key | null | undefined) => (
                  <OrderCardDanfe
                    key={index}
                    order={element}
                    term={filters.term}
                    type={filters.type}
                  />
                ),
              )}
          </S.Items>
          <S.Items>
            {isLoading && <S.LoadingAnimated src={LoadingAnimated} />}
          </S.Items>
          <S.Items>{!isLoading && errors && <p>{errors}</p>}</S.Items>
        </S.Orders>

        {/* Item de Carregamento */}
        {!isLoading && !errors?.length && !isLastPage && (
          <Pagination
            startWithZero={false}
            currentPage={pagination.current}
            paginationSize={pagination.size}
            paginationSearch={paginationSearch}
            totalPages={totalPages}
          />
        )}
      </S.Content>
      <DashboardFooter />
    </S.Container>
  );
};

const paths = [
  {
    title: 'Meu Painel',
    link: '/painel',
  },
  {
    title: 'Envio de DANFE e XML',
    link: '/envio-danfe-xml',
  },
];
