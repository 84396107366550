import { useState } from 'react';
import { IDanfeProps } from '../../interfaces';
import { danfeProvider } from '../../services/api';
import { useStorage } from '../useStorage';

interface ApiState {
  data: Response | null;
  multi: boolean | null;
  error: Error | null;
  isLoading: boolean;
}

export const useDanfe = () => {
  const { getItemOnStorage } = useStorage();

  const [apiState, setApiState] = useState<ApiState>({
    data: null,
    error: null,
    multi: null,
    isLoading: false,
  });

  const sendDanfeByEmail = async (searchParams: IDanfeProps) => {
    const { email, notaNumero, tipoNfe, empresaNumero, term, type } =
      searchParams;
    const currentUser = getItemOnStorage('currentUser');
    const user = JSON.parse(currentUser || '');
    const vendorEmail = user?.userEmail || '';
    const subscriptionNumber = type === 'cnpj' ? `&cnpjCliente=${term}` : '';
    const areasAtuacaoParam = user.areasAtuacao?.length
      ? encodeURIComponent(user.areasAtuacao.join(','))
      : '';

    setApiState({
      data: null,
      multi: null,
      error: null,
      isLoading: true,
    });

    try {
      if (email.includes(';')) {
        const promisesAll = email.split(';').map(async (elm: any) => {
          await danfeProvider.get(
            `api/NotaFiscal/ConsultaXML?empresaNumero=${empresaNumero}&tipoNfe=${tipoNfe}&notaNumero=${notaNumero}&emailCliente=${elm.replace(
              /\s/g,
              '',
            )}&emailRevendedor=${vendorEmail}&AreasAtuacao=${areasAtuacaoParam}` +
              subscriptionNumber,
          );
        });

        Promise.all(promisesAll).then(() => {
          setApiState({
            data: null,
            multi: true,
            error: null,
            isLoading: false,
          });
        });
        return;
      }

      const response: Response = await danfeProvider.get(
        `api/NotaFiscal/ConsultaXML?empresaNumero=${empresaNumero}&tipoNfe=${tipoNfe}&notaNumero=${notaNumero}&emailCliente=${email}&emailRevendedor=${vendorEmail}&AreasAtuacao=${areasAtuacaoParam}` +
          subscriptionNumber,
      );

      setApiState({
        data: response,
        multi: null,
        error: null,
        isLoading: false,
      });
      return;
    } catch (error: any) {
      setApiState(prevState => ({ ...prevState, error, isLoading: false }));
    } finally {
      setApiState(prevState => ({ ...prevState, isLoading: false }));
    }
  };

  const values = {
    apiState,
    sendDanfeByEmail,
  };
  return values;
};
