import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useDealer from '../../../hooks/useDealer/useDealer';
import { Accordion, Loading, Pagination } from '../../../components';
import { useWindowSize } from '../../../hooks';
import { LabelAndValue } from './Components/LabelAndValue';
import { Button, ButtonClean } from '../../../styles/lib/buttons';
import * as S from './styles';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { formatTimeStampToDate } from '../../../utils/functions';
import { InputContainer, PrimaryInput } from '../../../styles/lib/input';
import { FormSearch, ErroResponseArray, getStatusInfo } from './utils';

export const ActivityHistory: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitted },
  } = useForm<FormSearch>({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const { fetchDealerLogs, exportDealerLogs, isLoading, isLoadingExport } =
    useDealer();
  const [logs, setLogs] = useState<ErroResponseArray>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
    total: 0,
  });

  const { isDesktop } = useWindowSize();

  const getLogs = async ({
    search,
    page,
    pageSize,
  }: {
    search?: string;
    page: number;
    pageSize: number;
  }) => {
    try {
      const data = await fetchDealerLogs({
        page,
        pageSize,
        search,
      });
      if (data) {
        setPagination({
          current: data.data.paginacao.pagina,
          size: data.data.paginacao.tamanho,
          total: data.data.paginacao.total,
        });
        setLogs(data.data.logs);
      }
    } catch (error) {
      console.error('Erro ao buscar logs:', error);
      toast.error('Falha ao carregar os logs.');
    }
  };

  useEffect(() => {
    getLogs({
      page: pagination.current,
      pageSize: pagination.size,
      search: '',
    });
  }, []);

  const handleExportLogs = (id: number) => {
    exportDealerLogs(id);
  };

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= pagination.total) {
      window.scrollTo(0, 0);
      setPagination({
        current: index,
        size: pagination.size,
        total: pagination.total,
      });
      getLogs({
        page: index,
        pageSize: pagination.size,
        search: getValues('search'),
      });
    }
  };

  const onSubmit = async (data: FormSearch) => {
    await getLogs({
      page: pagination.current,
      pageSize: pagination.size,
      search: data?.search,
    });
  };

  const totalPages = Math.ceil(pagination?.total / pagination?.size);

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.GroupInputs>
          <InputContainer>
            <PrimaryInput
              {...register('search')}
              style={{ width: '400px' }}
              className="bg-white"
              type="text"
              placeholder="Pesquise por nome, método ou usuário."
            />
          </InputContainer>
          {/* <Select
              className="highlighted"
              {...register('perfis')}
              onChange={({ target }) => setValue('perfis', target.value)}
            >
              <Option value="">Todos Perfis</Option>
              {profileGroups
                .filter((group: IProfileGroup) => group.ativo)
                .map((group: IProfileGroup, index: number) => (
                  <Option key={index} value={group.nome}>
                    {group.nome}
                  </Option>
                ))}
            </Select>
            <InputDateStart
              type="date"
              min="2023-01-01"
              {...register('dateStart')}
              style={{ width: '140px' }}
            />

            <RadioButtonWrapper className="small horizontal">
              <label className="form-control">
                <input
                  type="radio"
                  value="todos"
                  {...register('options')}
                  checked={selectedValue === 'todos'}
                  onChange={() => setValue('options', 'todos')}
                />
                <span>Todos</span>
              </label>

              <label className="form-control">
                <input
                  type="radio"
                  value="sucesso"
                  {...register('options')}
                  checked={selectedValue === 'sucesso'}
                  onChange={() => setValue('options', 'sucesso')}
                />
                <span>Sucesso</span>
              </label>

              <label className="form-control">
                <input
                  type="radio"
                  value="erro"
                  {...register('options')}
                  checked={selectedValue === 'erro'}
                  onChange={() => setValue('options', 'erro')}
                />
                <span>Erro</span>
              </label>
            </RadioButtonWrapper> */}
        </S.GroupInputs>

        <S.ButtonGroups>
          <ButtonClean
            className="edit"
            type="button"
            style={{ width: '100px' }}
            onClick={() => reset()}
          >
            <IoIosCloseCircleOutline size={18} />
            Limpar tudo
          </ButtonClean>
          <Button
            type="submit"
            className="secondary"
            style={{
              width: '140px',
              height: '40px',
            }}
          >
            Buscar
          </Button>
        </S.ButtonGroups>
      </S.Form>

      {isSubmitted && !logs?.length && (
        <h6>Não foram encontrados logs de erro para a busca realizada</h6>
      )}

      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </div>
      ) : (
        <S.ListErrors>
          {logs.map((log, index) => {
            const { text, color } = getStatusInfo(log.status);
            const topContent = isDesktop
              ? [
                  {
                    label: 'Data / Hora',
                    value: formatTimeStampToDate(log.data || ''),
                  },
                  { label: 'Tipo de Ação', value: log.metodo },
                  { label: 'Status / Código', value: text, color },
                  { label: 'Administrador', value: log.nomeUsuarioAdmin },
                ]
              : [{ label: 'Status / Código', value: text, color }];

            const bottomContent = isDesktop
              ? [
                  { label: 'Usuário impactado', value: log.revendaImpactada },
                  { label: 'Perfil', value: log.descricaoPerfil },
                ]
              : [
                  {
                    label: 'Data / Hora',
                    value: formatTimeStampToDate(log.data || ''),
                  },
                  { label: 'Tipo de Ação', value: log.metodo },
                  { label: 'Administrador', value: log.nomeUsuarioAdmin },
                  { label: 'Usuário impactado', value: log.revendaImpactada },
                  { label: 'Perfil', value: log.descricaoPerfil },
                ];

            return (
              <Accordion
                id={`accordion-${index}`}
                key={index}
                openBgColor="#F5F5F5"
                triggerContent={
                  <S.AccordionContent
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  >
                    {topContent.map(({ label, value }, index) => (
                      <LabelAndValue
                        key={index}
                        label={label}
                        value={value}
                        color={label === 'Status / Código' ? color : undefined}
                      />
                    ))}
                  </S.AccordionContent>
                }
                content={
                  <>
                    <S.AccordionContent>
                      {bottomContent.map(({ label, value }, index) => (
                        <LabelAndValue
                          key={index}
                          label={label}
                          value={value}
                        />
                      ))}
                    </S.AccordionContent>
                    {isDesktop ? (
                      <S.TechnicalDetails>
                        <S.Header>
                          <h4>Detalhes técnicos</h4>
                          <Button
                            type="submit"
                            className="secondary"
                            style={{ maxWidth: '140px', height: '40px' }}
                            disabled={isLoadingExport}
                            onClick={() => handleExportLogs(log.id)}
                          >
                            {isLoadingExport ? 'Exportando...' : 'Exportar log'}
                          </Button>
                        </S.Header>
                        <S.Separator />
                        <S.Content>
                          <span>
                            <strong>Descrição do erro:</strong>
                            <p>{log.erro}</p>
                          </span>
                        </S.Content>
                      </S.TechnicalDetails>
                    ) : (
                      <Button
                        type="submit"
                        className="secondary"
                        style={{
                          maxWidth: '100%',
                          height: '40px',
                          marginTop: '16px',
                        }}
                        disabled={isLoadingExport}
                        onClick={() => handleExportLogs(log.id)}
                      >
                        {isLoadingExport ? 'Exportando...' : 'Exportar log'}
                      </Button>
                    )}
                  </>
                }
              />
            );
          })}
        </S.ListErrors>
      )}
      {!isLoading && logs?.length !== 0 && (
        <Pagination
          startWithZero={false}
          currentPage={pagination.current}
          paginationSize={pagination.size}
          paginationSearch={paginationSearch}
          totalPages={totalPages}
        />
      )}
    </S.Wrapper>
  );
};
