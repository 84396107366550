import * as S from './ModalStyles';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { Modal as ModalResponsive } from 'react-responsive-modal';
import { ButtonStyled } from '../ValidationDialogStyles';
import { GrAlert } from 'react-icons/gr';

interface IModalProps {
  title: string;
  contentTitle?: string | ReactNode;
  children: ReactNode;
  isOpenModal: boolean;
  handleOpenModal: Dispatch<SetStateAction<boolean>>;
  textClose: string;
  isError?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
  title,
  children,
  isOpenModal,
  handleOpenModal,
  textClose,
  contentTitle,
  isError = false,
}) => {
  const closeModal = () => {
    handleOpenModal(false);
  };

  return (
    <>
      {isOpenModal && (
        <ModalResponsive
          open={isOpenModal}
          onClose={closeModal}
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal ' + 'small-modal',
          }}
        >
          <S.ModalWrapper>
            <S.ModalHeader>
              <S.ModalTitle>{title}</S.ModalTitle>
            </S.ModalHeader>
            <S.ModalBody>
              {isError ? (
                <S.ErrorContent>
                  <>
                    <strong>
                      {isError && <GrAlert color="#ED5252" size={22} />}
                      {contentTitle ? contentTitle : null}
                    </strong>

                    {children}
                  </>
                </S.ErrorContent>
              ) : (
                <>
                  {contentTitle}
                  {children}
                </>
              )}
            </S.ModalBody>
            <S.ModalFooter>
              <ButtonStyled onClick={closeModal}>{textClose}</ButtonStyled>
            </S.ModalFooter>
          </S.ModalWrapper>
        </ModalResponsive>
      )}
    </>
  );
};
