import { toast } from 'react-toastify';
import { dealerProvider } from '../../services/api';
import { useState } from 'react';

export const useDealer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const registerDealer = async () => {
    const companyData = JSON.parse(
      sessionStorage.getItem('companyData') || '{}',
    );
    const locationData = JSON.parse(
      sessionStorage.getItem('locationData') || '{}',
    );
    const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');

    const dealerData = {
      nome: userData.nome || '',
      sobrenome: userData.sobrenome || '',
      email: userData.email || '',
      cnpj: companyData.cnpj || '',
      senha: '',
      inscricaoEstadual: companyData.inscricao || '',
      razaoSocial: companyData.razao || '',
      nomeFantasia: companyData.nomeFantasia || '',
      endereco: locationData.endereco || '',
      bairro: locationData.bairro || '',
      uf: locationData.uf || '',
      municipio: locationData.municipio || '',
      cep: locationData.cep || '',
      telefone: userData.telefone || '',
    };

    try {
      const response = await dealerProvider.post('/api/cadastrar', dealerData);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error('Erro ao cadastrar o revendedor:', error);
      toast.error('Erro ao cadastrar o revendedor');
    }
  };

  const fetchDealerLogs = async ({
    search = null,
    page,
    pageSize,
  }: {
    search?: string | null;
    page: number;
    pageSize: number;
  }) => {
    try {
      setIsLoading(true);
      const searchParam = search ? `&search=${search}` : '';
      const response = await dealerProvider.get(
        `/api/revendedor-allied/logs-integracao-erp-jundsoft?page=${page}&pageSize=${pageSize}${searchParam}`,
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Erro ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Erro ao buscar logs do revendedor:', error);
      toast.error('Erro ao buscar logs do revendedor');
    } finally {
      setIsLoading(false);
    }
  };

  const exportDealerLogs = async (id: number) => {
    try {
      setIsLoadingExport(true);
      const response = await dealerProvider.get(
        `/api/revendedor-allied/exportar-logs-integracao-erp-jundsoft?Id=${id}`,
        {
          responseType: 'arraybuffer',
        },
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/plain' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `revendedor-${id}.txt`;
        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(`Erro ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Erro ao exportar logs do revendedor:', error);
      toast.error('Erro ao exportar logs do revendedor');
    } finally {
      setIsLoadingExport(false);
    }
  };

  return {
    registerDealer,
    fetchDealerLogs,
    exportDealerLogs,
    isLoading,
    isLoadingExport,
  };
};

export default useDealer;
