import React, { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStorage } from '../../../hooks';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
} from '../../../components';
import { Users } from '../Users/Users';
import { Permissions } from '../../../pages/AccessControl/Permissions/Permissions';
import { Container, Content } from '../../../styles/lib/grid';
import {
  TabBadge,
  TabSelector,
  TabsNavigation,
  TabsSelector,
} from '../../../styles/lib/tabs';
import { TitleContent } from '../../../styles/lib/typography';
import { ActivityHistory } from '../ActivityHistory';

const TABS = {
  USERS: 'users',
  PERMISSIONS: 'permissions',
  ACTIVITY_HISTORY: 'activity-history',
} as const;

const PATHS = {
  PANEL: '/painel',
  ACCESS_CONTROL: '/controle-de-acesso',
  USERS: '/controle-de-acesso/usuarios',
  PERMISSIONS: '/controle-de-acesso/perfis',
  ACTIVITY_HISTORY: '/controle-de-acesso/historico-de-atividades',
} as const;

type TabType = keyof typeof TABS;

export const AccessTabNavigation: React.FC = () => {
  const { getItemOnStorage } = useStorage();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeTab: TabType = useMemo(() => {
    if (pathname.includes('usuarios')) return 'USERS';
    if (pathname.includes('perfis')) return 'PERMISSIONS';
    if (pathname.includes('historico-de-atividades')) return 'ACTIVITY_HISTORY';
    return 'USERS';
  }, [pathname]);

  const handleTabClick = (tab: TabType) => {
    navigate(PATHS[tab]);
  };

  const user = useMemo(
    () => JSON.parse(getItemOnStorage('currentUser') || '{}'),
    [getItemOnStorage],
  );
  const [totalEmAnalise, setTotalEmAnalise] = useState<string | null>(null);

  const pageTitle = useMemo(() => {
    switch (activeTab) {
      case 'USERS':
        return 'Controle de Acesso';
      case 'PERMISSIONS':
        return 'Gestão de Perfis';
      case 'ACTIVITY_HISTORY':
        return 'Histórico de Atividades';
      default:
        return '';
    }
  }, [activeTab]);

  const breadcrumbPaths = useMemo(
    () => [
      { title: 'Meu Painel', link: PATHS.PANEL },
      { title: 'Controle de Acesso', link: PATHS.ACCESS_CONTROL },
      { title: pageTitle, link: PATHS[activeTab] },
    ],
    [activeTab, pageTitle],
  );

  return (
    <Container>
      <Topbar />
      <Navbar />
      <Content className="modify-content">
        <Breadcrumb paths={breadcrumbPaths} />
        <TitleContent style={{ marginBottom: '3%' }}>{pageTitle}</TitleContent>

        <TabsNavigation>
          <TabsSelector className="secondary">
            <TabSelector
              className={activeTab === 'USERS' ? 'active' : ''}
              onClick={() => handleTabClick('USERS')}
            >
              Usuários
              <TabBadge className={activeTab === 'USERS' ? '' : 'inactive'}>
                {totalEmAnalise || '-'}
              </TabBadge>
            </TabSelector>
            <TabSelector
              className={activeTab === 'ACTIVITY_HISTORY' ? 'active' : ''}
              onClick={() => handleTabClick('ACTIVITY_HISTORY')}
            >
              Histórico de Atividades
            </TabSelector>
            {user?.perfil === 'administrador' && (
              <TabSelector
                className={activeTab === 'PERMISSIONS' ? 'active' : ''}
                onClick={() => handleTabClick('PERMISSIONS')}
              >
                Perfis
              </TabSelector>
            )}
          </TabsSelector>
        </TabsNavigation>
      </Content>

      {activeTab === 'USERS' && <Users setTotalEmAnalise={setTotalEmAnalise} />}
      {activeTab === 'PERMISSIONS' && <Permissions />}
      {activeTab === 'ACTIVITY_HISTORY' && <ActivityHistory />}

      <DashboardFooter />
    </Container>
  );
};
