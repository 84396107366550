import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Text = styled.div`
  width: 100%;
  margin: 0 0 8px;

  &.no-margin {
    margin: 0;
  }

  &.extended {
    width: 100%;
    max-width: 350px;
    color: #033b71;
  }

  &.bold {
    font-weight: 700;
  }

  &.top-margin {
    margin: 16px 0 8px;
  }

  &.inline {
    display: inline;
  }

  &.small-danger {
    font-size: 12px;
    color: red;
    margin-top: 1%;
  }

  &.small {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &.flexing {
    display: flex;
    align-items: center;
  }

  &.flexing {
    display: flex;
    align-items: center;
  }
`;

export const IconButton = styled.button`
  background: transparent;
  padding: 10px 16px;
  border-radius: 20px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: 0.2s ease all;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
  }

  &:hover {
    filter: brightness(0.9);
  }

  &.edit {
    background-color: #f7941d;
    color: #ffffff;
    border: 1px solid #f7941d;
  }

  &.analisys {
    color: #f7941d;
    border: 1px solid #f7941d;
  }

  &.deactivate {
    color: #f7941d;
    background-color: #ffffff;
    border: 1px solid #f7941d;
  }

  &.reactivate {
    color: #0d6000;
    border: 1px solid #0d6000;
  }

  &.text {
    color: #003b71;
  }

  &.primary {
    color: white;
    background-color: #00558f;
    font-weight: 700;

    &:hover {
      background-color: #00558fdd;
    }
  }

  &.medium {
    padding: 10px 20px;
  }

  &.success {
    color: white;
    background-color: #0d6000;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #0d6000e2;
    }
  }

  &.danger {
    color: white;
    background-color: #d30000;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #d30000ce;
    }
  }

  &.update {
    color: white;
    background-color: #f7941d;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #f7951ddd;
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 12px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #f4f4f4;

  &.floating {
    width: 150px;
    padding: 7px;
    border-radius: 16px;
    top: 8px;
    left: 8px;
    position: absolute;

    @media (max-width: 1024px) {
      width: 120px;
    }
  }
`;

export const Option = styled.option``;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 16px;
  margin: 32px 0 0;
`;

export const SearchCell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Search = styled.form`
  width: 100%;
  margin: 0px 0px 20px;
  display: flex;
  justify-content: space-between;
  min-width: 840px;
  gap: 16px;

  @media (max-width: 1280px) {
    min-width: 600px;
  }

  @media (max-width: 1024px) {
    min-width: 100% !important;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const InputContainer = styled.div`
  width: 75%;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 29px;
  background: transparent;
  border: 1px solid #e0e0e0;
  padding: 15px 25px 15px 175px;

  @media (max-width: 1024px) {
    padding: 15px 25px 15px 140px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 20%;
  gap: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.register {
    width: auto;
    margin-left: auto;
  }

  @media (max-width: 1024px) {
    min-width: 100% !important;
  }
`;

export const Button = styled.button`
  background-color: #f7941d;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &.excel {
    border: 2px solid #f7941d;
    background: transparent;
    color: #58595b;
  }

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 16px 24px;
`;

export const CardCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 8px;

  &.left {
    border-right: 1px solid black;
    padding-right: 16px;
  }
`;

export const CardText = styled.p`
  display: inline-block;

  &.active {
    font-size: 14px;
    font-weight: 400;
    color: #60ab26;
  }

  &.inactive {
    font-size: 14px;
    font-weight: 400;
    color: #58595b;
  }

  &.bolder {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  &.smallest {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }

  &.normal {
    font-size: 14px;
    font-weight: 400;
    color: black;
  }

  &.info {
    font-size: 20px;
    font-weight: 300;
    color: black;
  }
`;

export const Featured = styled.div`
  border-radius: 16px;
  padding: 16px 0;

  &.background {
    padding: 16px;
    background-color: #ededed;
  }

  &.margin-bottom {
    margin-bottom: 3%;
  }

  &.flexing {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;

  &.top-margin {
    margin-top: 16px;
  }

  &.between {
    justify-content: space-between !important;
  }
`;

export const RadioButtonWrapper = styled.div`
  color: #231f20;
  margin: 16px 0;
  font-size: 16px;

  .form-control {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }

  span {
    &.bolder {
      font-weight: 700;
    }
  }

  input[type='radio'] {
    /* ...existing styles */
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
  }
`;

export const InputReason = styled.textarea`
  width: 100%;
  border: 1px solid #999;
  padding: 12px;
  border-radius: 12px;
  margin: 8px 0 16px;
`;

export const PagItem = styled.button`
  background: transparent;
  transition: 0.2s ease all;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  width: 47px;
  height: 47px;

  &:hover {
    background-color: #f7941d;
    font-weight: bold;
    color: white;
  }

  &.is-selected {
    font-weight: bold;
    background-color: #eee;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

const primaryInputStyles = `
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #f4f4f4;
`;

const secondaryInputStyles = `
  background: white;
  border: 1px solid black;
  border-radius: 16px;
  margin: 8px 0;
`;

const placeholderStyles = `
  font-size: 13px;
  font-weight: 500;
  color: black;
  margin-left: 6px;
`;

const indicatorStyles = `
  color: black;
  padding: 0px 1px;
  width: 18px;
  height: 18px;

  &:hover {
    color: black;
  }
`;

export const CustomMultiSelect = styled(ReactSelect)`
  .Select__control {
    ${primaryInputStyles}

    .Select__value-container {
      padding: 6px 8px;

      .Select__placeholder {
        ${placeholderStyles}
      }
    }

    .Select__indicators {
      .Select__indicator-separator {
        display: none;
      }
      .Select__indicator {
        ${indicatorStyles}
      }
    }
  }

  .Select__menu {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
  }

  &.secondary {
    .Select__control {
      ${secondaryInputStyles}

      .Select__value-container {
        padding: 8px 8px;
      }
    }
  }

  &.secondary {
    .Select__control {
      ${secondaryInputStyles}

      .Select__value-container {
        padding: 8px 8px;
      }
    }
  }
`;

export const PagPrevious = styled(PagItem)``;

export const PagNext = styled(PagItem)``;

export const Message = styled.h4`
  text-align: center;
  color: #777;
`;

export const Row = styled.div`
  width: 100%;
`;

export const FormControl = styled.div`
  width: 100%;
  padding-bottom: 3%;
`;

export const ClientRegistrationForm = styled.form`
  width: 100%;
`;

export const CardButtons = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
  margin-left: auto;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ListInline = styled.div`
  ul {
    text-decoration: none;
    display: flex;
    gap: 5px;

    li {
      padding: 1% 2%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      background-color: #f5f5f5;
      list-style: none;
      font-size: 13px;
      span {
        font-weight: bold;
        font-size: 12px;
      }
    }

    &.no-flex {
      display: block;
      li {
        margin-top: 3px;
      }
    }
  }
`;
