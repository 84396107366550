import React, { ReactNode, useEffect, useState } from 'react';
import {
  Button,
  RadioButtonWrapper,
  ButtonClean,
} from '../../../../../styles/lib/buttons';
import { FaArrowLeft, FaPencil } from 'react-icons/fa6';
import { FaTimesCircle } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import { TitleDialog } from '../../../../../styles/lib/typography';
import { Loading, AlertBox, Tooltip } from '../../../../../components';
import {
  formatCNPJ,
  formatTimeStampToDate,
  formatZipcode,
  onlyNumbers,
} from '../../../../../utils/functions';
import { isBefore, parse, subYears } from 'date-fns';
import { useAccess } from '../../../../../hooks';
import * as S from './ValidationDialogStyles';
import { Modal } from './components/Modal';
import { Address } from '../../../../../interfaces';
import { InputContainer, PrimaryInput } from '../../../../../styles/lib/input';
import { IconCheck } from '../../../../../styles/lib/icons';
import { useForm } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const blockedDomains = ['gmail', 'yahoo', 'hotmail', 'outlook'];

const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .required()
      .test(
        'no-free-email',
        'Não aceitamos e-mails de domínios gratuitos (ex: Gmail, Yahoo)',
        value => !blockedDomains.some(domain => value?.includes(domain)),
      ),
    telefone: yup
      .string()
      .test('valid-phone', 'Número de telefone inválido', value => {
        const digits = onlyNumbers(value || '');
        return digits.length === 11;
      })
      .required(),
    nome: yup.string().required(),
  })
  .required();

interface ModalInfo {
  title: string;
  contentTitle?: string;
  description: string | ReactNode;
  isError: boolean;
  textToClose: string;
}

const DEFAULT_ADDRESS = {
  bairro: '',
  cep: '',
  endereco: '',
  municipio: '',
  uf: '',
};

export const ExpirationDate = ({
  expirationDate,
}: {
  expirationDate: string;
}) => {
  if (!expirationDate) return null;
  const isMoreThanOneYearVerify = (dateString: string) => {
    const inputDate = parse(dateString, 'dd/MM/yyyy', new Date());
    const oneYearAgo = subYears(new Date(), 1);
    return isBefore(inputDate, oneYearAgo);
  };

  const date = formatTimeStampToDate(expirationDate, true);

  const isMoreThanOneYear = isMoreThanOneYearVerify(date);
  return (
    <Tooltip content="Atente-se ás empresas com menos de um ano na Receita federal.">
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {date}
        <S.Tag isMoreThanOneYear={isMoreThanOneYear}>
          <S.Circle isMoreThanOneYear={isMoreThanOneYear} />
          {`${isMoreThanOneYear ? 'Mais' : 'Menos'} de um ano`}
        </S.Tag>
      </div>
    </Tooltip>
  );
};

const ValidationUserDialog: React.FC<{
  isOpen: boolean;
  user: any;
  onClose: VoidFunction;
  allowEdit?: boolean;
}> = ({ allowEdit, isOpen, onClose, user }) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const registerWithMask = useHookFormMask(register);
  const [isApprove, setIsApprove] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [isEnabledToEdit, setIsEnabledToEdit] = useState(false);

  const [addressJund, setAddressJund] = useState<Address>(DEFAULT_ADDRESS);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    title: '',
    contentTitle: '',
    description: '',
    textToClose: 'Ok',
    isError: false,
  });

  const {
    analyzeUser,
    editUser,
    returnAllUsers,
    isLoadingAPICall,
    verifyAddressJund,
  } = useAccess();

  useEffect(() => {
    if (user?.id) {
      setAddressJund(DEFAULT_ADDRESS);
    }
  }, [user]);

  const formatAddress = (
    endereco?: string,
    bairro?: string,
    municipio?: string,
    uf?: string,
  ): string => {
    return [endereco, bairro, municipio, uf].filter(Boolean).join(', ');
  };

  const handleRadioChange = (approve: boolean) => {
    setIsApprove(approve);
  };

  const handleEditUser = async () => {
    const response = await editUser({
      id: user?.id,
      contato: {
        nome: getValues('nome'),
        telefone: onlyNumbers(getValues('telefone')),
      },
      login: { email: getValues('email') },
    });

    if (response.success) {
      setIsEnabledToEdit(false);
    }
  };

  const handleConsultJundAndApproveUser = async () => {
    const response = await verifyAddressJund(user?.cnpj, user?.id);
    const isTheSameAddress =
      user?.cep === response?.data?.cep &&
      user?.endereco === response?.data.endereco;

    if (response?.data?.cep && !isTheSameAddress) {
      setModalInfo({
        title: 'Atenção',
        contentTitle: 'Erro na consulta ao JUND.',
        description:
          'O endereço informado no cadastro é diferente do endereço registrado no JUND.',
        isError: true,
        textToClose: 'Fechar',
      });
      setAddressJund(response?.data);
      setOpenModalInfo(true);
    } else if (response?.status === 500) {
      setModalInfo({
        title: 'Atenção',
        contentTitle: 'Erro de Conexão com o Sistema.',
        description:
          'Não foi possível consultar os dados na JUND no momento. Aguarde uns instantes e tente novamente.',
        isError: true,
        textToClose: 'Fechar',
      });
      setAddressJund(response?.data);
      setOpenModalInfo(true);
    } else {
      await handleApproveUser();
    }
  };

  const handleApproveUser = async () => {
    const response = await analyzeUser(user?.id, isApprove, '');

    if (response?.status === 200) {
      setModalInfo({
        title: `${
          isApprove ? 'Aprovação' : 'Reprovação'
        } realizada com sucesso!`,
        contentTitle: '',
        description: (
          <p>
            Você {isApprove ? 'aprovou' : 'reprovou'} o acesso do usuário{' '}
            <strong>{user?.razaoSocial}.</strong>
          </p>
        ),
        isError: false,
        textToClose: 'Ok',
      });
      setOpenModalInfo(true);
    } else {
      setModalInfo({
        title: 'Atenção',
        contentTitle: 'Erro de Conexão com o Sistema.',
        description:
          'Não foi possível consultar os dados na JUND no momento. Aguarde uns instantes e tente novamente.',
        isError: true,
        textToClose: 'Fechar',
      });
      setOpenModalInfo(true);
    }
  };

  const onSubmit = async () => {
    await handleEditUser();
  };

  const handleCloseValidationDialog = () => {
    setIsEnabledToEdit(false);
    reset();
    onClose();
  };

  return (
    <>
      <S.Overlay
        isOpen={isOpen}
        onClick={() => handleCloseValidationDialog()}
      />
      <Modal
        title={modalInfo.title}
        contentTitle={modalInfo?.contentTitle}
        isOpenModal={openModalInfo}
        textClose={modalInfo.textToClose}
        handleOpenModal={() => setOpenModalInfo(false)}
        isError={modalInfo.isError}
      >
        {modalInfo.description}
      </Modal>
      <S.ModalWrapper isOpen={isOpen}>
        <S.Container>
          <S.Header>
            <Button
              className="outline rounded"
              style={{ minWidth: '40px' }}
              onClick={() => handleCloseValidationDialog()}
            >
              <FaArrowLeft />
            </Button>

            <TitleDialog>
              {allowEdit ? 'Edição de renvededor' : 'Análise de novo acesso'}
            </TitleDialog>
            <Tooltip content="Você está analisando a solicitação de acesso de revendedor Allied. Confira os dados abaixo.">
              <MdInfoOutline
                style={{
                  width: '1.1em',
                  height: '1.1em',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </S.Header>
          {isLoadingAPICall ? (
            <S.LoadingWrapper>
              <Loading />
              {!allowEdit && (
                <p>
                  Aguarde uns instantes enquanto consultamos informações no
                  Jund...
                </p>
              )}
            </S.LoadingWrapper>
          ) : (
            <>
              {addressJund?.cep ? (
                <>
                  <S.Content>
                    <S.Section>
                      <h4 style={{ marginBottom: '16px' }}>
                        Correção do endereço
                      </h4>
                      <AlertBox>
                        O endereço informado não corresponde ao registrado na
                        JUND. Clique em
                        <strong>{' "Concordar e aprovar" '}</strong>
                        para usar o endereço oficial e avançar com a consulta.
                      </AlertBox>

                      <hr style={{ margin: '16px 0' }} />

                      <S.CardInformation>
                        <strong>Endereço informado pelo solicitante:</strong>
                        <S.AddressInfo style={{ marginTop: '16px' }}>
                          <S.ListInfo>
                            <span>Cep:</span>
                            <li>{formatZipcode(user?.cep || '') || ''}</li>
                          </S.ListInfo>
                          <S.ListInfo>
                            <span>Endereço:</span>
                            <li>
                              {formatAddress(
                                user?.endereco,
                                user?.bairro,
                                user?.municipio,
                                user?.uf,
                              )}
                            </li>
                          </S.ListInfo>
                        </S.AddressInfo>
                      </S.CardInformation>
                      <S.CardInformation style={{ marginTop: '16px' }}>
                        <strong>Endereço registrado no JUND:</strong>
                        <S.AddressInfo style={{ marginTop: '16px' }}>
                          <S.ListInfo>
                            <span>Cep:</span>
                            <li>
                              {formatZipcode(addressJund?.cep || '') || ''}
                            </li>
                          </S.ListInfo>
                          <S.ListInfo>
                            <span>Endereço:</span>
                            <li>
                              {formatAddress(
                                addressJund?.endereco,
                                addressJund?.bairro,
                                addressJund?.municipio,
                                addressJund?.uf,
                              )}
                            </li>
                          </S.ListInfo>
                        </S.AddressInfo>
                      </S.CardInformation>
                    </S.Section>
                  </S.Content>
                  <S.Footer>
                    <S.ButtonStyled
                      type="submit"
                      className="secondary"
                      onClick={handleApproveUser}
                      disabled={isLoadingAPICall}
                    >
                      Confirmar
                    </S.ButtonStyled>
                  </S.Footer>
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <S.Content>
                    <S.Section>
                      <S.HeaderSection>
                        <h4 style={{ width: '100%' }}>Informações de Login</h4>
                        {allowEdit && !isEnabledToEdit && (
                          <ButtonClean
                            className="edit"
                            onClick={() => setIsEnabledToEdit(true)}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <FaPencil />
                            Alterar informações
                          </ButtonClean>
                        )}
                        {isEnabledToEdit && (
                          <ButtonClean
                            className="edit"
                            onClick={() => {
                              setIsEnabledToEdit(false);
                              reset();
                            }}
                          >
                            <FaTimesCircle />
                            Cancelar alteração
                          </ButtonClean>
                        )}
                      </S.HeaderSection>{' '}
                      <S.CardInformation>
                        <S.ListInfo>
                          <span>
                            E-mail informado:
                            <Tooltip content="E-mail para login.">
                              <MdInfoOutline
                                style={{
                                  width: '1.1em',
                                  height: '1.1em',
                                  color: 'gray',
                                  cursor: 'pointer',
                                }}
                              />
                            </Tooltip>
                          </span>
                          {!isEnabledToEdit ? (
                            <li>{getValues('email') || user?.email}</li>
                          ) : (
                            <>
                              <InputContainer>
                                <PrimaryInput
                                  {...register('email')}
                                  defaultValue={user?.email}
                                  className="bg-white small"
                                  type="text"
                                  maxLength={64}
                                />
                                {!errors.email && dirtyFields?.email && (
                                  <IconCheck />
                                )}
                              </InputContainer>
                              <p
                                style={{
                                  fontSize: '12px',
                                  color: '#696969',
                                  padding: '4px',
                                }}
                              >
                                Não aceitamos domínios gratuitos, como Gmail,
                                Yahoo ou similares.
                              </p>
                            </>
                          )}
                        </S.ListInfo>
                        <hr style={{ marginTop: '16px' }} />

                        <S.ListInfo>
                          <span>E-mail cadastrado na Receita Federal:</span>
                          <li>
                            {user?.emailAlteradoReceitaFederal ? '' : 'Não há'}
                          </li>
                        </S.ListInfo>
                      </S.CardInformation>
                    </S.Section>

                    <S.Section>
                      <h4>Informações na Receita Federal</h4>
                      <S.CardInformation>
                        <S.ListInfo isRow>
                          <li>
                            <span>CNPJ:</span>
                            {formatCNPJ(user?.cnpj || '')}
                          </li>

                          <li>
                            <span>Expedição:</span>
                            <ExpirationDate
                              expirationDate={user?.dataSituacaoCadastral}
                            />
                          </li>
                        </S.ListInfo>
                        <hr style={{ marginTop: '16px' }} />
                        <S.ListInfo>
                          <li>
                            <span>Inscrição Estadual:</span>
                            {user?.inscricaoEstadual}
                          </li>
                          <li>
                            <span>Razão social:</span>
                            {user?.razaoSocial}
                          </li>
                          <li>
                            <span>Nome fantasia:</span>
                            {user?.nomeFantasia}
                          </li>
                        </S.ListInfo>
                      </S.CardInformation>
                    </S.Section>

                    <S.Section>
                      <S.HeaderSection>
                        <h4 style={{ width: '100%' }}>
                          Informações de Contato
                        </h4>
                        {allowEdit && !isEnabledToEdit && (
                          <ButtonClean
                            className="edit"
                            onClick={() => setIsEnabledToEdit(true)}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <FaPencil />
                            Alterar informações
                          </ButtonClean>
                        )}
                        {isEnabledToEdit && (
                          <ButtonClean
                            className="edit"
                            onClick={() => setIsEnabledToEdit(false)}
                          >
                            <FaTimesCircle />
                            Cancelar alteração
                          </ButtonClean>
                        )}
                      </S.HeaderSection>{' '}
                      <S.CardInformation>
                        <S.ListInfo isRow>
                          <li style={{ marginLeft: '8px' }}>
                            <span>Ponto de contato:</span>
                            {!isEnabledToEdit ? (
                              <>{getValues('nome') || user?.nomeContato}</>
                            ) : (
                              <>
                                <InputContainer>
                                  <PrimaryInput
                                    {...register('nome')}
                                    defaultValue={user?.nomeContato}
                                    className="bg-white small block"
                                    type="text"
                                  />
                                  {!errors.nome && dirtyFields?.nome && (
                                    <IconCheck />
                                  )}
                                </InputContainer>
                              </>
                            )}
                          </li>
                          <li style={{ marginLeft: '8px' }}>
                            <span>Telefone:</span>
                            {!isEnabledToEdit ? (
                              <>{getValues('telefone') || user?.telefone}</>
                            ) : (
                              <>
                                <InputContainer>
                                  <PrimaryInput
                                    {...registerWithMask(
                                      'telefone',
                                      ['(99) 9 9999-9999'],
                                      {
                                        required: true,
                                      },
                                    )}
                                    defaultValue={user?.telefoneContato}
                                    className="bg-white small block"
                                    type="text"
                                  />
                                  {!errors.telefone &&
                                    dirtyFields?.telefone && <IconCheck />}
                                </InputContainer>
                              </>
                            )}
                          </li>
                        </S.ListInfo>
                      </S.CardInformation>
                    </S.Section>

                    <S.Section>
                      <S.HeaderSection>
                        <h4 style={{ width: '100%' }}>
                          Informações de endereço
                        </h4>
                      </S.HeaderSection>
                      <S.CardInformation>
                        <S.ListInfo isRow>
                          <li style={{ marginLeft: '8px' }}>
                            <span>Cep:</span>
                            {formatZipcode(user?.cep)}
                          </li>
                          <li style={{ marginLeft: '8px' }}>
                            <span>endereco:</span>

                            <>{user?.endereco}</>
                          </li>
                        </S.ListInfo>
                      </S.CardInformation>
                    </S.Section>

                    {!allowEdit && (
                      <RadioButtonWrapper className="small">
                        <label
                          className="form-control"
                          onClick={() => handleRadioChange(true)}
                        >
                          <input
                            type="radio"
                            name="radio"
                            checked={isApprove}
                          />
                          <span>Sim, liberar acesso ao usuário.</span>
                        </label>
                        <label
                          className="form-control"
                          onClick={() => handleRadioChange(false)}
                        >
                          <input
                            type="radio"
                            name="radio"
                            checked={!isApprove}
                          />
                          <span>Não, negar acesso ao usuário.</span>
                        </label>
                      </RadioButtonWrapper>
                    )}
                  </S.Content>
                  <S.Footer>
                    {!allowEdit ? (
                      <S.ButtonStyled
                        type="submit"
                        className="secondary"
                        onClick={handleConsultJundAndApproveUser}
                        disabled={isLoadingAPICall}
                      >
                        Confirmar
                      </S.ButtonStyled>
                    ) : (
                      <S.ButtonStyled
                        type="submit"
                        className="secondary"
                        disabled={
                          !isValid ||
                          !isEnabledToEdit ||
                          !isDirty ||
                          isLoadingAPICall
                        }
                      >
                        Salvar
                      </S.ButtonStyled>
                    )}
                  </S.Footer>
                </form>
              )}
            </>
          )}
        </S.Container>
      </S.ModalWrapper>
    </>
  );
};

export default ValidationUserDialog;
