import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { Topbar, LoginForm, LoginFooter } from '../../components';
import CommunicationDealersDialog from '../../components/CommunicationDealersDialog/CommunicationDealersDialog';

export const Login: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('r') === '1') {
      setIsModalOpen(true);
    }
  }, []);

  return (
    <S.Container>
      <Topbar />
      <LoginForm />
      <LoginFooter />
      {isModalOpen && (
        <CommunicationDealersDialog onClose={() => setIsModalOpen(false)} />
      )}
    </S.Container>
  );
};
