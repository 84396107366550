import React from 'react';
import { MdOutlineLightbulb } from 'react-icons/md';
import * as S from './styles';

interface AlertBoxProps {
  children: React.ReactNode;
}

export const AlertBox: React.FC<AlertBoxProps> = ({ children }) => {
  return (
    <S.BoxWrapper>
      <MdOutlineLightbulb size={24} color="#007bff" />
      <S.Message>{children}</S.Message>
    </S.BoxWrapper>
  );
};
