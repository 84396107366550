import React from 'react';
import * as ReactTooltip from '@radix-ui/react-tooltip';
import './styles.css';

export const Tooltip = ({
  children,
  content,
  side = 'top',
}: {
  content: string | React.ReactNode;
  children: React.ReactNode;
  side?: 'top' | 'left' | 'bottom' | 'right' | undefined;
}) => {
  return (
    <ReactTooltip.Provider delayDuration={120}>
      <ReactTooltip.Root>
        <ReactTooltip.Trigger asChild>
          <button className="IconButton">{children}</button>
        </ReactTooltip.Trigger>
        <ReactTooltip.Portal>
          <ReactTooltip.Content
            className="ReactTooltipContent"
            sideOffset={5}
            side={side}
          >
            {content}
            <ReactTooltip.Arrow className="ReactTooltipArrow" />
          </ReactTooltip.Content>
        </ReactTooltip.Portal>
      </ReactTooltip.Root>
    </ReactTooltip.Provider>
  );
};
