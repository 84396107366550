import styled from 'styled-components';

export const BoxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  background-color: #eef5ff;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #007bff;
  svg {
    flex-shrink: 0;
  }
`;

export const Message = styled.p`
  font-size: 14px;
  color: #333;
  line-height: 1.4;
  strong {
    font-weight: bold;
  }
`;
