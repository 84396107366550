import React, { useState, useEffect } from 'react';
import DownloadExcel from '../../assets/download-excel.png';
import * as DF from '../../styles/lib/dynamic-filter';
import {
  IDynamicFilterData,
  IDynamicFilterSelectOptions,
} from '../../interfaces';

interface DynamicFilterProps {
  filterSelectOptions: IDynamicFilterSelectOptions[];
  filters: IDynamicFilterData;
  extraFilterSelectOptions?: IDynamicFilterSelectOptions[];
  calendar?: boolean;
  isLoading: boolean;
  dataTreatment?: (value: IDynamicFilterData) => IDynamicFilterData;
  searchFilters: (
    formData: IDynamicFilterData & { extraType?: string },
  ) => void;
  setFilters: (value: IDynamicFilterData) => void;
  isInputDisabled?: boolean;
  handleExport?: () => void;
  handleClearSearch?: () => void;
  placeholder?: string;
}

export const DynamicFilter = ({
  filterSelectOptions,
  calendar = false,
  extraFilterSelectOptions,
  isLoading,
  filters,
  searchFilters,
  dataTreatment,
  handleExport,
  setFilters,
  isInputDisabled = false,
  handleClearSearch,
  placeholder = 'Digite sua busca...',
}: DynamicFilterProps) => {
  const [typingFilters, setTypingFilters] =
    useState<IDynamicFilterData>(filters);
  const [customPlaceholder, setCustomPlaceholder] =
    useState<string>(placeholder);

  const handleChange = (newFilters: IDynamicFilterData) => {
    if (dataTreatment) {
      const treatedFilters = dataTreatment(newFilters);
      setTypingFilters(treatedFilters);
    } else {
      setTypingFilters(newFilters);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    } else if (e.key === 'Backspace' && typingFilters.term === '') {
      handleClearSearch && handleClearSearch();
    }
  };

  const handleSubmit = () => {
    searchFilters(typingFilters);
    setFilters(typingFilters);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = filterSelectOptions.find(
      option => option.value === e.target.value,
    );
    const newPlaceholder = selectedOption?.placeholder || placeholder;

    handleChange({
      ...typingFilters,
      type: e.target.value,
      term: '',
      dateStart: new Date(new Date().setDate(new Date().getDate() - 45))
        .toISOString()
        .split('T')[0],
      dateEnd: new Date(new Date().setDate(new Date().getDate() + 45))
        .toISOString()
        .split('T')[0],
    });
    setCustomPlaceholder(newPlaceholder);
  };

  return (
    <DF.Search>
      <DF.InputContainer>
        <DF.Stack>
          <DF.Select value={typingFilters.type} onChange={handleSelectChange}>
            {filterSelectOptions.map((option, index) => (
              <DF.Option key={index} value={option.value}>
                {option.description}
              </DF.Option>
            ))}
          </DF.Select>
          <DF.Input
            maxLength={35}
            value={typingFilters.term}
            onChange={e => {
              handleChange({
                ...typingFilters,
                term: e.target.value,
              });
            }}
            onKeyUp={handleKeyPress}
            placeholder={customPlaceholder}
            disabled={isInputDisabled}
          />
        </DF.Stack>
      </DF.InputContainer>

      {calendar && (
        <DF.Box>
          <DF.InputDateStart
            type="date"
            min="2023-01-01"
            value={typingFilters.dateStart}
            onChange={e => {
              handleChange({ ...typingFilters, dateStart: e.target.value });
            }}
          />
          <DF.SeparatorHorizontal />
          <DF.InputDateEnd
            type="date"
            min={typingFilters.dateStart}
            value={typingFilters.dateEnd}
            onChange={e => {
              handleChange({ ...typingFilters, dateEnd: e.target.value });
            }}
          />
        </DF.Box>
      )}

      {extraFilterSelectOptions && (
        <DF.Select
          className={'highlighted'}
          value={typingFilters.extraType}
          onChange={e => {
            handleChange({ ...typingFilters, extraType: e.target.value });
          }}
        >
          {extraFilterSelectOptions.map((option, index) => (
            <DF.Option key={index} value={option.value}>
              {option.description}
            </DF.Option>
          ))}
        </DF.Select>
      )}

      <DF.ButtonsContainer>
        <DF.Button onClick={() => handleSubmit()}>Buscar</DF.Button>
        {handleExport && (
          <DF.Button
            onClick={handleExport}
            disabled={isLoading}
            className={'excel ' + (isLoading && 'is-disabled')}
            type="button"
          >
            <DF.ExcelDownload src={DownloadExcel} />
            Excel
          </DF.Button>
        )}
      </DF.ButtonsContainer>
    </DF.Search>
  );
};
