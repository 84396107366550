import styled from 'styled-components';
import * as c from '../palette';

export const IconButton = styled.button`
  background: transparent;
  padding: 8px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: 0.2s ease all;
  cursor: pointer;

  &.disabled {
    color: white;
    background-color: ${c.gray} !important;
    font-weight: 700;
    padding: 8px 16px;
    opacity: 0.3;
  }

  &.edit {
    color: ${c.gray};
    border: 1px solid ${c.gray};

    &:hover {
      background-color: #e2e2e2;
    }
  }

  &.analisys {
    color: #f7941d;
    border: 1px solid #f7941d;

    &:hover {
      background-color: #f7951d2d;
    }
  }

  &.deactivate {
    color: #eb001b;
    border: 1px solid #eb001b;

    &:hover {
      background-color: #eb001b1f;
    }
  }

  &.reactivate {
    color: #0d6000;
    border: 1px solid #0d6000;

    &:hover {
      background-color: #0d60002e;
    }
  }

  &.text {
    color: #003b71;
  }

  &.success {
    color: white;
    background-color: #0d6000;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #0d6000e2;
    }
  }

  &.danger {
    color: white;
    background-color: #d30000;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #d30000ce;
    }
  }

  &.update {
    color: white;
    background-color: #f7941d;
    font-weight: 700;
    padding: 8px 16px;

    &:hover {
      background-color: #f7951ddd;
    }
  }

  &.info {
    color: ${c.textPrimary};
  }

  &.refresh {
    padding: 6px 7px 6px 6px;
    img {
      width: 18px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  padding: 5% 0 3% 0;
  gap: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    min-width: 100% !important;
  }

  &.right {
    float: right;
    @media (max-width: 1024px) {
      min-width: 0 !important;
    }
  }
`;

export const Link = styled.a`
  color: ${c.orange};
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
  &.primary {
    margin-top: 3%;
    color: #222;
  }
`;

export const ButtonClean = styled.button`
  background: transparent;
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: right;
  color: ${c.textPrimary};
  align-items: center;
  display: flex;
  gap: 8px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

export const Button = styled.button`
  background-color: #f7941d;
  width: 100%;
  height: 50px;
  border-radius: 80px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &.link {
    background: transparent;
    height: auto;
  }

  &.excel {
    border: 2px solid #f7941d;
    background: transparent;
    color: ${c.gray};
  }

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &:disabled {
    background-color: #d3d3d3;
    color: #a0a0a0;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.small {
    width: 15%;
    height: 40px;
  }
  &.auto-width {
    width: auto;
    min-width: 120px;
  }

  &.outline {
    border: 2px solid ${c.orange};
    background: transparent;
    color: ${c.orange};
    &:hover {
      background: ${c.orange};
      color: white;
    }
  }

  &.mobile-block {
    @media (max-width: 600px) {
      width: 100%;
      margin: 5% 0;
    }
  }

  &.rounded {
    border-radius: 50px;
    width: auto;
    min-width: 50px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;

  &.top-margin {
    margin-top: 16px;
  }
`;

export const RadioButtonWrapper = styled.div`
  color: #231f20;
  margin: 16px 0;
  font-size: 16px;

  &.horizontal {
    display: flex;
    flex-direction: row !important;
    margin-top: 0;
    gap: 8px;
  }

  .form-control {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }

  span {
    &.bolder {
      font-weight: 700;
    }
  }

  input[type='radio'] {
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
  }

  &.small {
    font-size: 14px;
    input[type='radio'] {
      width: 17px;
      height: 17px;
    }
  }

  input[type='radio']::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
  }
`;

export const LinkWrapper = styled.div`
  padding: 43px 0 0 43px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const TextLink = styled.a`
  cursor: pointer;
  color: #133d6d;
  font-size: 20px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
