import styled from 'styled-components';

import { Button } from '../../../../../styles/lib/buttons';
export const ButtonStyled = styled(Button)`
  width: 140px;

  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 37%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  border-radius: 27px 0 0 27px;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  transform: translateX(${props => (props.isOpen ? '0' : '100%')});

  @media (max-width: 640px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100vh;
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

export const Section = styled.section`
  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

export const Header = styled.div`
  padding: 28px;
  display: flex;
  align-items: center;
  gap: 8px;
  button {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }
`;

export const Content = styled.div`
  padding: 0 28px 28px 28px;
  margin-bottom: 28px;
  height: 100%;
`;

export const ListInfo = styled.ul<{ isRow?: boolean }>`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 600;
  &:not(:first-child) {
    margin-top: 16px;
  }

  li {
    &:first-child {
      width: 180px;
    }
    font-weight: 400;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  span {
    font-weight: 600;
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export const CardInformation = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  padding: 16px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AddressInfo = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Footer = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 104px;
  padding: 32px;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
  box-shadow: 3px 3px 19px 0px #00000030;
`;

export const Tag = styled.div<{ isMoreThanOneYear: boolean }>`
  display: flex;
  border-radius: 50px;
  padding: 2px 6px;
  border: 1px solid #84848f;
  background-color: ${({ isMoreThanOneYear }) =>
    isMoreThanOneYear ? '#1bc1671a ' : '#ED52521A'};
  align-items: center;
  width: 100%;
  gap: 4px;
`;

export const Circle = styled.div<{ isMoreThanOneYear: boolean }>`
  width: 16px;
  height: 16px;
  background-color: ${({ isMoreThanOneYear }) =>
    isMoreThanOneYear ? '#1bc167 ' : '#ED5252'};
  border-radius: 50%;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  align-items: center;
  justify-content: center;
  p {
    max-width: 396px;
    text-align: center;
  }
`;
