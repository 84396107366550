import React, { useState, useEffect } from 'react';
import {
  CloseButton,
  Description,
  DialogContainer,
  DialogOverlay,
  ImageContainer,
  StyledImage,
  Timer,
  Title,
  Content,
  Footer,
} from './CommunicationDealersDialog.styles';
import Banner from '../../assets/Banner25.png';

interface CommunicationDealersDialogProps {
  onClose: () => void;
}

export const CommunicationDealersDialog: React.FC<
  CommunicationDealersDialogProps
> = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prev => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    if (timer === 0) {
      setIsOpen(false);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds,
    ).padStart(2, '0')}`;
  };

  if (!isOpen) return null;

  return (
    <DialogOverlay>
      <DialogContainer>
        <Content>
          <div>
            <Title>Allied Empresas agora está no Autosserviço.</Title>
            <Description>
              O site Allied Empresas está em reformulação e será lançado em
              breve. Por enquanto, você pode contar com o Autosserviço para suas
              necessidades de autoatendimento.
            </Description>
            <Timer>
              Este aviso será fechado em alguns segundos: {formatTime(timer)}
            </Timer>
            <br />
            <CloseButton onClick={() => setIsOpen(false)}>Fechar</CloseButton>
          </div>
          <ImageContainer>
            <StyledImage src={Banner} alt="Descrição da imagem" />
          </ImageContainer>
        </Content>
      </DialogContainer>
    </DialogOverlay>
  );
};

export default CommunicationDealersDialog;
