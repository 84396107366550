import axios from 'axios';
import { generateToken, removeToken } from './token';

const createApiClient = (baseURL: string) => {
  const apiInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  apiInstance.interceptors.request.use(
    async config => {
      const accessToken = await generateToken();
      if (accessToken && config?.headers) {
        config.headers.Authorization = `Bearer ${accessToken || ''}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  apiInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        removeToken();
      }
      return Promise.reject(error);
    },
  );

  return apiInstance;
};

export default createApiClient;
