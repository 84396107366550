import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 24px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  label {
    font-weight: 500;
    font-size: 13px;
  }

  &.block {
    max-width: 100%;
    width: 100%;
  }

  h3 {
    margin: 8px 0;
    font-size: 22px;
    font-weight: 400;
  }

  p {
    font-size: 13px;
    font-weight: 400;
    color: #666;
    &.center {
      text-align: center;
    }
    &.size16 {
      font-size: 16px;
      margin-top: 5px;
    }
  }

  .card-img {
    padding: 7% 0;
  }

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
    transition: all 0.3s ease-in-out;
  }

  &.animation-off {
    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transform: none;
      transition: none;
    }
  }

  .flex {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: between;
    gap: 25%;

    > div:first-child {
      width: 100%;
    }

    > div:last-child {
      width: 100%;
    }
  }
`;
