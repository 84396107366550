import createApiClient from './createApiClient';

export const identityProvider = createApiClient(
  process.env.REACT_APP_KEY_IDENTITY_PROVIDER || '',
);
export const dealerProvider = createApiClient(
  process.env.REACT_APP_KEY_DEALER_PROVIDER || '',
);
export const clientProvider = createApiClient(
  process.env.REACT_APP_KEY_CLIENT_PROVIDER || '',
);
export const orderProvider = createApiClient(
  process.env.REACT_APP_KEY_ORDER_PROVIDER || '',
);
export const danfeProvider = createApiClient(
  process.env.REACT_APP_KEY_DANFE_PROVIDER || '',
);
export const boletosProvider = createApiClient(
  process.env.REACT_APP_KEY_BOLETOS_PROVIDER || '',
);
export const logsProvider = createApiClient(
  process.env.REACT_APP_KEY_LOGS_PROVIDER || '',
);
export const commissioningProvider = createApiClient(
  process.env.REACT_APP_KEY_COMMISSIONING_PROVIDER || '',
);
