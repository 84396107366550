import { useState } from 'react';
import { logsProvider, dealerProvider } from '../../services/api';
import { useStorage } from '../useStorage';

const HORA_INICIAL = ' 00:00:00';
const HORA_FINAL = ' 23:59:59';

export const useLogs = () => {
  const { getItemOnStorage } = useStorage();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSingleLog, setIsLoadingSingleLog] = useState(false);
  const [isLoadingAPICall, setIsLoadingAPICall] = useState(false);
  const [logs, setLogs] = useState<any>([]);
  const [usersLogs, setUsersLogs] = useState<any>([]);
  const [singleLog, setSingleLog] = useState<any>([]);
  const [metadata, setMetadata] = useState<any>([]);
  const [apiError, setApiError] = useState<any>(null);

  const currentUser = getItemOnStorage('currentUser') || '{"data":"empty"}';
  const user = JSON.parse(currentUser);

  const handleAPIError = (e: any) => {
    setIsLoadingAPICall(false);
    setApiError(
      e.response.data?.errors ||
        e.response ||
        e.message ||
        'Erro desconhecido na API',
    );
  };

  const returnAllLogs = async (searchParams: any) => {
    const { type, term, currentPage, pageSize, dateStart, dateEnd } =
      searchParams;
    setLogs([]);
    setMetadata([]);
    setIsLoading(true);
    try {
      const response = await logsProvider.get(
        `api/Log/listar?Search=${type}&Text=${term}&Page=${currentPage}&PageSize=${pageSize}&DataInicial=${
          dateStart + HORA_INICIAL
        }&DataFinal=${dateEnd + HORA_FINAL}`,
      );
      setLogs(response.data.data.logs);
      setMetadata(response.data.data);
    } catch (e: any) {
      handleAPIError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const returnSingleLog = async (searchParams: any) => {
    const { id } = searchParams;
    setIsLoadingSingleLog(true);
    try {
      const response = await logsProvider.get(`api/Log/buscar?Id=${id}`);
      setSingleLog(response.data.data);
    } catch (e: any) {
      handleAPIError(e);
    } finally {
      setIsLoadingSingleLog(false);
    }
  };

  const returnAllUsersLogs = async (searchParams: any) => {
    const { type, term, currentPage, pageSize, dateStart, dateEnd } =
      searchParams;
    setUsersLogs([]);
    setMetadata([]);
    setIsLoading(true);
    try {
      const response = await dealerProvider.get(
        `api/logs?Search=${type}&Text=${term}&Page=${currentPage}&PageSize=${pageSize}&DataInicial=${
          dateStart + HORA_INICIAL
        }&DataFinal=${dateEnd + HORA_FINAL}`,
      );
      setUsersLogs(response.data.data.representantes);
      setMetadata(response.data.data.pagination);
    } catch (e: any) {
      handleAPIError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const returnAllProfileLogs = async (searchParams: any) => {
    const { type, term, currentPage, pageSize, dateStart, dateEnd } =
      searchParams;
    setUsersLogs([]);
    setMetadata([]);
    setIsLoading(true);

    const searchID = type ? `&IdPerfilEditado=${type}` : '';

    try {
      const response = await dealerProvider.get(
        `api/Perfil/listarlogsperfilpermissao?Page=${currentPage}&PageSize=${pageSize}&DataInicial=${
          dateStart + HORA_INICIAL
        }&DataFinal=${dateEnd + HORA_FINAL}` + searchID,
      );
      setUsersLogs(response.data.data.logsPerfilPermissoes);
      setMetadata(response.data.data);
    } catch (e: any) {
      handleAPIError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    logs,
    usersLogs,
    singleLog,
    metadata,
    isLoading,
    isLoadingAPICall,
    isLoadingSingleLog,
    apiError,
    returnAllLogs,
    returnSingleLog,
    returnAllUsersLogs,
    setIsLoadingSingleLog,
    returnAllProfileLogs,
  };
};
