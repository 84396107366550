import React, { useState } from 'react';
import * as S from './styles';

interface AccordionTriggerProps {
  children: React.ReactNode;
  isOpen: boolean;
}

interface AccordionContentProps {
  children: React.ReactNode;
  isOpen: boolean;
  openBgColor?: string;
}

interface AccordionProps {
  id: string;
  triggerContent: React.ReactNode;
  content: React.ReactNode;
  openBgColor?: string;
}
export const Accordion: React.FC<AccordionProps> = ({
  id,
  triggerContent,
  content,
  openBgColor,
}) => {
  const [open, setOpen] = useState<string[]>([]);
  const isOpen = open.includes(id);
  return (
    <S.Root
      type="multiple"
      value={open}
      onValueChange={setOpen}
      openBgColor={openBgColor}
      isOpen={isOpen}
    >
      <S.Item value={id}>
        <AccordionTrigger isOpen={isOpen}>{triggerContent}</AccordionTrigger>
        {isOpen && <S.Separator />}
        <AccordionContent openBgColor={openBgColor} isOpen={isOpen}>
          {content}
        </AccordionContent>
      </S.Item>
    </S.Root>
  );
};

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, isOpen, ...props }, forwardedRef) => (
  <S.Header>
    <S.Trigger {...props} ref={forwardedRef}>
      {children}
      <S.ChevronDown isOpen={isOpen} size={28} />
    </S.Trigger>
  </S.Header>
));

AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, isOpen, ...props }, forwardedRef) => (
  <S.Content isOpen={isOpen} {...props} ref={forwardedRef}>
    {children}
  </S.Content>
));

AccordionContent.displayName = 'AccordionContent';
