import { useState } from 'react';
import { dealerProvider } from '../../services/api';
import {
  IRegisterFinalClient,
  User,
  VerifyAddressResponse,
} from '../../interfaces';
import { sendNotification } from '../../utils/functions';

export const useAccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAPICall, setIsLoadingAPICall] = useState(false);
  const [representantes, setRepresentantes] = useState<any>([]);
  const [metadata, setMetadata] = useState<any>([]);
  const [error, setError] = useState<any>(null);

  const handleAPIError = (e: any) => {
    setIsLoadingAPICall(false);
    setError(
      e.response.data.errors ||
        e.response ||
        e.message ||
        'Erro desconhecido na API',
    );
  };

  const performAPIRequest = async (url: string, data: any) => {
    try {
      setIsLoadingAPICall(true);
      const response = await dealerProvider.post(url, data);

      setIsLoadingAPICall(false);

      return response;
    } catch (e: any) {
      setIsLoadingAPICall(false);
      handleAPIError(e);
      sendNotification(`Error - ${e.response.data.errors || 'desconhecido'}`, {
        type: 'error',
      });
    }
  };

  const editUser = async (user: User) => {
    setIsLoadingAPICall(true);
    try {
      const response = await dealerProvider.put<User>(
        'api/revendedor-allied',
        user,
      );

      return response?.data;
    } catch (e: any) {
      return e?.response;
    } finally {
      setIsLoadingAPICall(false);
    }
  };

  const verifyAddressJund = async (cnpj: string, id: string) => {
    setIsLoadingAPICall(true);
    try {
      const response = await dealerProvider.get<VerifyAddressResponse>(
        'api/revendedor-allied/erp-jundsoft',
        {
          params: {
            cnpj,
            id,
          },
        },
      );

      return response?.data;
    } catch (e: any) {
      return e?.response;
    } finally {
      setIsLoadingAPICall(false);
    }
  };

  const returnAllUsers = async ({
    type,
    term,
    tipoPerfil,
    pageSize,
    currentPage,
  }: {
    type?: string;
    term?: string;
    currentPage?: number;
    pageSize?: number;
    tipoPerfil?: string;
  }) => {
    const params: Record<string, string> = {};

    if (type) params['search'] = type;
    if (term) params['text'] = term;
    if (currentPage) params['page'] = currentPage.toString();
    if (pageSize) params['pagesize'] = pageSize.toString();
    if (tipoPerfil) params['tipoPerfil'] = tipoPerfil;

    const queryString = Object.keys(params)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    const url = `api/listar${queryString ? `?${queryString}` : ''}`;
    try {
      setIsLoading(true);
      const response = await dealerProvider.get(url);

      setMetadata(response.data.data);
      setRepresentantes(response.data.data);
    } catch (e: any) {
      handleAPIError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const changeUserStatus = async (id: string, activate: boolean) => {
    await performAPIRequest('api/trocastatus', { id, ativo: activate });
  };

  const editUserInfo = async (user: any) => {
    const { id, areasAtuacao, grupoEconomico, perfil, cnpjsRaiz } = user || {};

    const requestData = {
      id,
      areasAtuacao,
      grupoEconomico,
      cnpjsRaiz,
      perfil: {
        id: perfil.id,
        nome: perfil.nome,
      },
    };

    return performAPIRequest('api/editar', requestData);
  };

  const analyzeUser = async (
    id: string,
    analyzed: boolean,
    text?: string,
    user?: any,
  ) => {
    const requestData: any = {
      id,
      analisado: analyzed,
      justificativa: text,
      gruposEconomicos: user?.gruposEconomicos,
    };

    try {
      setIsLoadingAPICall(true);
      try {
        const response = await dealerProvider.post('api/analise', requestData);
        await returnAllUsers({
          currentPage: 1,
          pageSize: 20,
        });
        return response;
      } catch (e: any) {
        handleAPIError(e);
      } finally {
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoadingAPICall(false);
      handleAPIError(error);
      sendNotification(
        `Error - ${error.response.data.errors || 'desconhecido'}`,
        {
          type: 'error',
        },
      );
    } finally {
      setIsLoadingAPICall(false);
    }
  };

  const registerUser = async (user: any) => {
    try {
      setIsLoading(true);
      setIsLoadingAPICall(true);
      setError(null);
      const newUser = await dealerProvider.post('api/cadastrar-para-analise', {
        nome: user.nome,
        cnpj: user.cnpj,
        email: user.email,
        areaAtuacao: user.areaAtuacao || '',
        perfil: {},
      });
      setIsLoading(false);
      return newUser;
    } catch (e: any) {
      handleAPIError(e);
      return e;
    } finally {
      setIsLoadingAPICall(false);
    }
  };

  const registerFinalClient = async (
    data: IRegisterFinalClient,
  ): Promise<boolean> => {
    try {
      const { cnpjRaiz, grupoEconomico, emails, areasAtuacao } = data;

      setIsLoadingAPICall(true);
      setError(null);

      await dealerProvider.post('api/FilaRepresentanteCadastro/enviar', {
        cnpjRaiz,
        grupoEconomico,
        emails,
        areasAtuacao,
      });

      setIsLoadingAPICall(false);
      return true;
    } catch (e: any) {
      handleAPIError(e);
      setIsLoadingAPICall(false);
      return false;
    }
  };

  return {
    representantes,
    verifyAddressJund,
    metadata,
    isLoading,
    isLoadingAPICall,
    error,
    returnAllUsers,
    changeUserStatus,
    editUserInfo,
    editUser,
    analyzeUser,
    registerUser,
    registerFinalClient,
    handleAPIError,
  };
};
