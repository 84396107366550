import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0px 20px 40px;
  width: 100%;
  margin: 0 auto;
  max-width: 1268px;
  min-height: 670px;

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }
`;

export const Form = styled.form`
  display: flex;
  gap: 24px;
  margin: 16px 0;
  justify-content: space-between;

  @media (max-width: 1024px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const GroupInputs = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ButtonGroups = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const ListErrors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const AccordionContent = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TechnicalDetails = styled.div`
  background-color: #fff;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-size: 16px;
  }
`;

export const Separator = styled.div`
  margin: 16px 0px;
  background-color: #d7d7db;
  height: 1px;
`;

export const Content = styled.div`
  span {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
`;
