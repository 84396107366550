import * as AccordionUI from '@radix-ui/react-accordion';
import { BsChevronDown } from 'react-icons/bs';
import styled, { css, keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: max-content;
  }
`;

const slideUp = keyframes`
  from {
    max-height: max-content;
  }
  to {
    max-height: 0;
  }
`;

export const Root = styled(AccordionUI.Root)<{
  openBgColor?: string;
  isOpen?: boolean;
}>`
  border-radius: 6px;
  width: 100%;
  background-color: ${({ isOpen, openBgColor }) =>
    isOpen && openBgColor ? openBgColor : '#fff'};
  box-shadow: 1px 2px 5px 0px #00000033;
`;

export const Item = styled(AccordionUI.Item)`
  overflow: hidden;
  margin-top: 1px;
  height: 100%;
  &:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px #211f26;
  }
`;

export const Header = styled(AccordionUI.Header)`
  all: unset;
  display: flex;
`;

export const Trigger = styled(AccordionUI.Trigger)`
  height: 100%;
  font-family: inherit;
  background-color: transparent;
  padding: 24px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
`;

export const Separator = styled.div`
  margin: 0 24px;
  background-color: #d7d7db;
  height: 1px;
`;

export const Content = styled(AccordionUI.Content)<{
  openBgColor?: string;
  isOpen: boolean;
}>`
  overflow: hidden;

  padding: 24px;
  background-color: ${({ isOpen, openBgColor }) =>
    isOpen && openBgColor ? openBgColor : '#fff'};
  max-height: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      animation: ${slideDown} 0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
    `}

  ${({ isOpen }) =>
    !isOpen &&
    css`
      animation: ${slideUp} 0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
    `}
`;

export const ChevronDown = styled(BsChevronDown)`
  flex-shrink: 0;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  transform: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? 'rotate(180deg)' : 'rotate(0)'};
`;
