import styled from 'styled-components';

export const ModalWrapper = styled.div`
  padding: 32px;
`;

export const ModalHeader = styled.div``;
export const ModalTitle = styled.div`
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 43.58px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 24px 0;

  p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #51515c;
    strong {
      gap: 8px;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 21.79px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;

  border: 1px solid #ed5252;
  background-color: #ed52521a;

  strong {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;
export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
