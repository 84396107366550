import React, { useState, useEffect } from 'react';
import { InputContainer, PrimaryInput } from '../../../../../styles/lib/input';
import { Button } from '../../../../../styles/lib/buttons';
import { useCNPJ } from '../../../../../hooks/useCNPJ/useCNPJ';
import {
  formatDocumentCNPJ,
  formatStateRegistration,
} from '../../../../../utils/functions';
import { IconCheck } from '../../../../../styles/lib/icons';
import { HintError } from '../../../../../styles/lib/typography';
import WarningIcon from '../../../../../assets/icons/warning.svg';
import { FormWrapper, ResponsiveRow, Row } from '../../Styles/DealerDesignGrid';

const INITIAL_COMPANY_DATA = {
  cnpj: '',
  stateRegistration: '',
  corporateName: '',
  tradeName: '',
  fieldsDisabled: true,
};
export const DealerCompanyStep: React.FC = () => {
  const [companyData, setCompanyData] = useState(INITIAL_COMPANY_DATA);

  const [isValid, setIsValid] = useState(false);
  const [stateRegistrationError, setStateRegistrationError] = useState('');
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCompanyData(prevState => ({
      ...prevState,
      [id]: id === 'cnpj' ? formatDocumentCNPJ(value) : value,
    }));
  };

  const { fetchCNPJData, loading, error, limitError } = useCNPJ();

  useEffect(() => {
    sessionStorage.setItem('companyData', JSON.stringify(companyData));
  }, [companyData]);

  useEffect(() => {
    if (error) {
      setCompanyData(INITIAL_COMPANY_DATA);
    }
  }, [error]);

  const handleApplyCNPJ = async () => {
    const unmaskedCNPJ = companyData.cnpj.replace(/\D/g, '');
    const fetchedData = await fetchCNPJData(unmaskedCNPJ);

    if (fetchedData) {
      const {
        inscricoes_estaduais,
        estado,
        logradouro,
        tipo_logradouro,
        numero,
        complemento,
        bairro,
        cep,
        cidade,
        email,
      } = fetchedData.estabelecimento;

      const inscricaoEstadual = inscricoes_estaduais.find(
        (item: any) => item.ativo && item.estado.sigla === estado.sigla,
      )?.inscricao_estadual;

      setCompanyData({
        ...companyData,
        stateRegistration:
          inscricoes_estaduais.length === 0
            ? 'Isento'
            : inscricaoEstadual
            ? formatStateRegistration(inscricaoEstadual)
            : '',
        corporateName: fetchedData.razao_social || '',
        tradeName: fetchedData.razao_social || '',
        fieldsDisabled: true,
      });

      const location = {
        cep,
        bairro,
        logradouro: `${tipo_logradouro} ${logradouro}`,
        municipio: cidade.nome,
        estado: estado.sigla,
        numero,
        complemento,
      };

      sessionStorage.setItem('locationData', JSON.stringify(location));
      sessionStorage.setItem('emailData', email);

      setStateRegistrationError(
        inscricaoEstadual || inscricoes_estaduais.length === 0
          ? ''
          : 'Não foi encontrada uma inscrição estadual ativa para este CNPJ.',
      );
    }
  };

  useEffect(() => {
    const { cnpj, stateRegistration, corporateName, tradeName } = companyData;

    if (cnpj && stateRegistration && corporateName && tradeName) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [companyData]);

  useEffect(() => {
    if (limitError) {
      const match = limitError.match(/Liberação ocorrerá em (.+)/);
      if (match) {
        const releaseTime = new Date(match[1]);
        const updateTimer = () => {
          const now = new Date();
          const diff = releaseTime.getTime() - now.getTime();
          if (diff <= 0) {
            setTimeRemaining(0);
          } else {
            setTimeRemaining(Math.ceil(diff / 1000));
          }
        };
        updateTimer();
        const intervalId = setInterval(updateTimer, 1000);
        return () => clearInterval(intervalId);
      }
    }
  }, [limitError]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <FormWrapper>
      {limitError && timeRemaining !== null && timeRemaining > 0 && (
        <>
          <p className="error-message">
            <img src={WarningIcon} alt="Warning" />
            {'  '}
            Atenção: Limite de consultas da Receita Federal excedido. Por favor,
            aguarde 1 minuto e tente novamente.
          </p>
          <p className="timer">
            Você poderá tentar novamente em:{' '}
            <strong>{formatTime(timeRemaining)}</strong>
          </p>
        </>
      )}
      <Row style={{ alignItems: 'end' }}>
        <div>
          <label htmlFor="cnpj">CNPJ</label>
          <InputContainer>
            <PrimaryInput
              id="cnpj"
              type="text"
              placeholder="XX.XXX.XXX/XXXX-XX"
              value={companyData.cnpj}
              onChange={handleChange}
              className={`rounded margin-bottom-off ${
                companyData.cnpj ? 'check' : ''
              }`}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </div>
        <Button
          className="auto-width outline"
          type="button"
          onClick={handleApplyCNPJ}
          disabled={
            loading || companyData.cnpj.replace(/\D/g, '').length !== 14
          }
        >
          {loading ? 'Carregando...' : 'Aplicar'}
        </Button>
      </Row>

      {error && <HintError className="small">{error}</HintError>}
      <ResponsiveRow>
        <div style={{ minWidth: '37%' }}>
          <label htmlFor="stateRegistration">Inscrição Estadual</label>
          <InputContainer>
            <PrimaryInput
              id="stateRegistration"
              type="text"
              placeholder="xxx.xxx.xxx.xxx"
              value={companyData.stateRegistration}
              onChange={handleChange}
              disabled={companyData.fieldsDisabled}
              className={`rounded ${
                companyData.stateRegistration ? 'check' : ''
              }`}
            />
            {isValid && <IconCheck />}
          </InputContainer>
          {stateRegistrationError && (
            <HintError className="small">{stateRegistrationError}</HintError>
          )}
        </div>
        <div style={{ minWidth: '50%' }}>
          <label htmlFor="corporateName">Razão Social</label>
          <InputContainer>
            <PrimaryInput
              id="corporateName"
              type="text"
              placeholder="Razão social cadastrada na Receita Federal"
              value={companyData.corporateName}
              onChange={handleChange}
              disabled={companyData.fieldsDisabled}
              className={`rounded ${companyData.corporateName ? 'check' : ''}`}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </div>
      </ResponsiveRow>
      <div style={{ marginTop: '16px' }}>
        <label htmlFor="tradeName">Nome Fantasia</label>
        <InputContainer>
          <PrimaryInput
            id="tradeName"
            type="text"
            placeholder="Insira o nome comercial da empresa"
            value={companyData.tradeName}
            onChange={handleChange}
            disabled={companyData.fieldsDisabled}
            className={`rounded ${companyData.tradeName ? 'check' : ''}`}
          />
          {isValid && <IconCheck />}
        </InputContainer>
      </div>
    </FormWrapper>
  );
};
