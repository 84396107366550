import React from 'react';
import styled from 'styled-components';

interface LabelAndValueProps {
  label: string;
  value: React.ReactNode;
  color?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: auto;
  min-width: 140px;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const LabelAndValue = ({ label, value, color }: LabelAndValueProps) => {
  return (
    <Wrapper>
      <strong>{label}</strong>
      <span style={{ color, fontWeight: color ? 'bold' : 'normal' }}>
        {value}
      </span>
    </Wrapper>
  );
};
