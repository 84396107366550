import { sendNotification } from '../utils/functions';

export const generateToken = async () => {
  const hasAccessToken = localStorage.getItem('token');

  return isTokenValid() ? hasAccessToken : null;
};

export const removeToken = () => {
  const REDIRECT_TIME = 6000;

  const toastConfig = {
    autoClose: REDIRECT_TIME,
    hideProgressBar: false,
    type: 'error',
  };

  sendNotification(
    'Token Expirado, você será redirecionado para realizar login novamente.',
    toastConfig,
  );

  localStorage.removeItem('token');
  localStorage.setItem('callback_url', window.location.href);

  setTimeout(() => {
    window.location.replace('/');
  }, REDIRECT_TIME);
};

const isTokenValid = () => {
  const token = localStorage.getItem('token');
  localStorage.removeItem('callback_url');

  if (token) {
    const payload = token.split('.')[1];
    const decodedPayload = JSON.parse(atob(payload));
    const expirationDate = decodedPayload.exp * 1000;

    return Date.now() < expirationDate;
  }

  return null;
};
