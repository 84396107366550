import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import * as S from './UsersCardStyles';
import { UserCardProps } from '../../interfaces';
import { SlArrowRight } from 'react-icons/sl';
import { formatCNPJ } from '../../utils/functions';
import { useWindowSize } from '../../hooks';

const Cell: React.FC<{ label: string; value?: string; className?: string }> = ({
  label,
  value,
  className,
}) => (
  <S.Cell className={className}>
    <S.Text>{value || '-'}</S.Text>
    <S.Text>
      <span>{label}</span>
    </S.Text>
  </S.Cell>
);

export const UsersCard: React.FC<UserCardProps> = ({
  user,
  children,
  isAccordion = true,
  action,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { width } = useWindowSize();

  const isDesktop = width >= 1024;

  const handleClick = useCallback(() => {
    if (isAccordion) {
      setIsActive(prev => !prev);
    } else if (action) {
      action();
    }
  }, [isAccordion, action]);

  const topContent = isDesktop
    ? [
        { label: 'Razão Social', value: user?.razaoSocial },
        { label: 'CNPJ', value: formatCNPJ(user?.cnpj || '') },
        { label: 'Ponto de contato', value: user?.nomeContato },
        { label: 'Perfil do usuário', value: user?.perfil?.nome },
      ]
    : [{ label: 'Razão Social', value: user?.razaoSocial }];

  const bottomContent = isDesktop
    ? [{ label: 'E-mail', value: user?.email }]
    : [
        { label: 'CNPJ', value: formatCNPJ(user?.cnpj || '') },
        { label: 'Ponto de contato', value: user?.nomeContato },
        { label: 'Perfil do usuário', value: user?.perfil?.nome },
      ];

  return (
    <S.Container
      className={classNames({ active: isActive })}
      style={
        !user?.analisado
          ? { background: '#1BC1670D', border: '1px solid #1BC167' }
          : {}
      }
    >
      <S.Top onClick={handleClick}>
        <S.TopWrapper className={classNames({ active: isActive })}>
          {topContent.map(({ label, value }, index) => (
            <Cell key={index} label={label} value={value} />
          ))}
          <SlArrowRight
            size={18}
            color="#51515C"
            style={{
              rotate: isActive ? '-90deg' : '90deg',
              transition: 'ease 0.4s',
            }}
          />
        </S.TopWrapper>
      </S.Top>

      {isAccordion && (
        <S.Bottom className={classNames({ active: isActive })}>
          {bottomContent.map(({ label, value }, index) => (
            <Cell key={index} label={label} value={value} />
          ))}
          <S.GroupButtons>{children}</S.GroupButtons>
        </S.Bottom>
      )}
    </S.Container>
  );
};
