import { useCallback, useState } from 'react';
import { clientProvider, dealerProvider } from '../../services/api';
import { IClientGroupState } from '../../interfaces';

export const useClient = (): [IClientGroupState, (cnpj: string) => any] => {
  const [groupState, setGroupState] = useState<IClientGroupState>({
    isLoading: false,
    reject: null,
    cnpjConsulted: [],
  });

  const fetchData = useCallback(
    async (endpoint: string, onSuccess: (data: any) => any) => {
      setGroupState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const response: any = await clientProvider.get(endpoint);

        onSuccess(response.data.data);

        setGroupState(prevState => ({
          ...prevState,
          reject: null,
          isLoading: false,
        }));
      } catch (err: any) {
        const errorMessage =
          err.response?.data.errors || err.message || 'Erro desconhecido';

        setGroupState(prevState => ({
          ...prevState,
          reject: errorMessage,
          isLoading: false,
        }));
      }
    },
    [],
  );

  const postData = useCallback(async (endpoint: string, data: any) => {
    try {
      setGroupState(prevState => ({ ...prevState, isLoading: true }));
      const response = await clientProvider.post(endpoint, data);

      setGroupState(prevState => ({ ...prevState, error: null }));

      return response.data.data;
    } catch (err: any) {
      setGroupState(prevState => ({
        ...prevState,
        error: err as any,
        modalLoading: false,
      }));
    } finally {
      setGroupState(prevState => ({ ...prevState, isLoading: false }));
    }
  }, []);

  const consultingCnpj = useCallback(
    async (cnpj: string) => {
      return new Promise((resolve, reject) => {
        try {
          fetchData(`api/buscar?CpfCnpj=${cnpj.replace(/\D/g, '')}`, data => {
            setGroupState(prevState => ({
              ...prevState,
              cnpjConsulted: data,
            }));
            resolve(data);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    [fetchData],
  );

  return [groupState, consultingCnpj];
};
